import React, { useState } from "react";
import type { NavbarItem } from "@lib/store";
import { loaderVisibility } from "@lib/store.ts";
import { FaExternalLinkAlt } from "react-icons/fa";

type DropdownProps = {
  title: string;
  subMenu: NavbarItem[];
  lastItem: boolean;
};

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className="relative z-50 isolate">
      <div
        className="group"
        onMouseOver={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}>
        <button className="flex items-center gap-1 text-sm w-full xlg:w-auto transition xlg:px-2 py-2 text-tollguru-text hover:text-tollguru-primary focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-tollguru-primary focus-visible:outline-none focus-visible:shadow-outline-indigo rounded-full">
          <span>{props.title}</span>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="3"
            stroke="currentColor"
            className={`w-3 h-3 mt-0.5 ${isOpen && "rotate-180"}`}>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
          </svg> */}
        </button>
        {isOpen && (
          <div className="pageInEffectUp">
            <div
              className={`
              page-loader xlg:absolute w-full xlg:w-48 z-50 border-l xlg:border-0 border-slate-200
          ${
            props.lastItem
              ? " xlg:right-0 origin-top-right"
              : " xlg:left-0 origin-top-left"
          }
        `}>
              <div className="px-3 xlg:py-2 xlg:bg-white xlg:rounded-md xlg:shadow xlg:border flex flex-col">
                {props.subMenu.map((item, index) => (
                  <a
                    key={index}
                    href={item.path}
                    onClick={() =>
                      window.location.pathname !== item.path &&
                      loaderVisibility.set({ isLoaderVisible: true })
                    }
                    className="page-loader px-3 py-1.5 text-sm text-gray-600 hover:text-tollguru-primary hover:bg-blue-50 rounded-md focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-tollguru-primary focus-visible:outline-none focus-visible:shadow-outline-indigo">
                    {item.title === "GitHub" ? (
                      <div className="flex justify-between items-center">
                        <span>{item.title}</span>
                        <span className="text-xs">
                          <FaExternalLinkAlt />
                        </span>
                      </div>
                    ) : (
                      item.title
                    )}
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};
